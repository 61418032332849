import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { key: "design" }
const _hoisted_3 = { key: "display" }
const _hoisted_4 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.mode === 'DESIGN')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_el_input, {
            size: "default",
            disabled: "",
            placeholder: _ctx.placeholder
          }, null, 8, ["placeholder"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (!_ctx.readerMode)
            ? (_openBlock(), _createBlock(_component_el_input, {
                key: 0,
                modelValue: _ctx._value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx._value) = $event)),
                size: "default",
                clearable: "",
                placeholder: _ctx.placeholder
              }, null, 8, ["modelValue", "placeholder"]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                textContent: _toDisplayString(_ctx._value)
              }, null, 8, _hoisted_4))
        ]))
  ]))
}